<template>
  <div>
    <div class="d-flex calendar-event" :class="obj.color">
      <div class="col-3 date-col">
        <div class="e-times">
          <div class="e-start-time">{{ obj.sTime }}</div>
          <div class="e-end-time">{{ obj.eTime }}</div>
          <div class="e-end-time" v-if="obj.attachment">
            <iPaperclip />
            <a :href="obj.attachment" class="ml-2" target="_blank">{{ obj.flyer }}</a>
          </div>
        </div>
      </div>
      <div class="col-9" @click="getItem">
        <div class="e-title">{{ obj.title }}</div>
        <div class="e-desc">{{ obj.eventname }}</div>
        <div class="e-desc">{{ obj.description }}</div>
        <div class="e-desc_label" :class="{ e_desc_label_blitz: !!obj.blzKey }" v-if="obj.label !== null">{{ obj.label }}</div>
        <img
          v-if="obj.vmrt_trn_txt !== null && obj.vmrt_trn_txt !== `Once`"
          :src="require(`@/assets/repeat.png`)"
          :height="22"
          :width="22"
          v-b-tooltip.hover="'This is a recurrence meeting'"
        />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import BIcon from 'bootstrap-vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'

export default {
  name: 'CalendarWeek',
  props: {
    obj: Object,
  },
  components: {
    'b-icon': BIcon,
    iPaperclip: iPaperclip,
  },
  methods: {
    getItem() {
      this.isOpen = true
      this.$emit('onSelected', this.$props.obj)
    },
  },
  created() {},
}
</script>

<style lang="scss">
/* eslint-disable */
@import '@/styles/settings.scss';
.calendar-event {
  padding-top: 9px;
  padding-bottom: 9px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  &.card-Gideon .date-col:after {
    background-color: #1ca3c2;
  }
  &.card-Auxillary .date-col:after {
    background-color: #fb4401;
  }
  &.card-Both .date-col:after {
    background-color: #2a0049;
  }
  .date-col {
    display: flex;
    align-items: center;
    @include breakpoint(sm) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .e-times {
      flex: 1 0 auto;
      justify-content: center;
      .e-start-time,
      .e-end-time {
        text-align: center;
        font-size: 20px;
        color: #636363;
        @include breakpoint(sm) {
          font-size: 18px;
        }
      }
    }
    &:after {
      content: '';
      height: 95px;
      width: 6px;
      background-color: red;
      display: block;
    }
  }
  .col-9 {
    padding-left: 85px;
    @include breakpoint(sm) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    cursor: pointer;
  }
  .e-title {
    font-weight: 600;
    font-size: 20px;
    color: #636363;
    @include breakpoint(sm) {
      font-size: 18px;
    }
  }
  .e-desc {
    font-size: 18px;
    color: #878787;
    @include breakpoint(sm) {
      font-size: 16px;
    }
  }

  .e-desc_label {
    font-size: 12px;
    color: red;
    @include breakpoint(sm) {
      font-size: 12px;
    }
  }

  .e_desc_label_blitz {
    font-size: 18px;
    color: #878787;
    @include breakpoint(sm) {
      font-size: 16px;
    }
  }
}
</style>
